<template>
  <div>
    <div class="grid cor-painel">
      <div class="field col-12 sm:col-6 md:col-5 lg:col-4 xl:col-2">
        <label>Categoria </label>
        <select
          class="p-inputtext p-component"
          disabled
          v-model="categoriaSelectionada"
          style="appearance: revert !important; width: 100% !important"
        >
          <option v-for="info in listaCategoria" :value="info">
            {{ info.nome }}
          </option>
        </select>
      </div>
      <div v-if="listaTipos.length > 0" class="field col-12 sm:col-6 md:col-6 lg:col-6 xl:col-4">
        <label>Filtro</label>
        <select
          @change="escolaSelecionada = ''; buscarRelatorio();"
          class="p-inputtext p-component"
          v-model="filtroSelectionado"
          style="appearance: revert !important; width: 100% !important"
        >
          <option v-for="info in listaTipos" :value="info">
            {{ info.nome }}
          </option>
        </select>
      </div>
      <div v-if="filtroSelectionado != '' && !filtroSelectionado.nome.includes('POR ESCOLA') && !filtroSelectionado.nome.includes('POR SEGMENTO') && !filtroSelectionado.nome.includes('ABAIXO DO ESPERADO') && !filtroSelectionado.nome.includes('MÉDIA POR ESCOLA') && !filtroSelectionado.nome.includes('MÉDIA POR SEGMENTO')" class="field col-12 sm:col-6 md:col-6 lg:col-6 xl:col-3">
        <label>Selecione a escola</label>
        <select
          @change="buscarRelatorio();"
          class="p-inputtext p-component"
          v-model="escolaSelecionada"
          style="appearance: revert !important; width: 100% !important"
        >
          <option v-for="info in listaDeEscolas" :value="info">
            {{ info.nome }}
          </option>
        </select>
      </div>
    </div>
    <div class="col-12">
    </div>
    <div v-if="exibirComponent">
      <div style="margin-left: 12px;" v-if="categoriaSelectionada.nome.includes('TRANSFERIDO') || categoriaSelectionada.nome == 'EVADIDOS' ">
        <InfoTransferidos :tipo="categoriaSelectionada.nome.toLowerCase()"  :filtro="filtroSelectionado" :ano="ano" :escola_id="escolaSelecionada.id" :nomeEscola="escolaSelecionada.nome"/>
      </div>
      <div style="margin-left: 12px;" v-if="categoriaSelectionada.nome.includes('ALUNOS ATIVOS EM TURMA')">
        <AlunosAtivo :tipo="categoriaSelectionada.nome.toLowerCase()"  :filtro="filtroSelectionado" :ano="ano" :escola_id="escolaSelecionada.id" :nomeEscola="escolaSelecionada.nome"/>
      </div>
      <div style="margin-left: 12px;" v-if="categoriaSelectionada.nome.includes('MÉDIA (NOTA) DOS ALUNOS')">
        <InfoNotas :tipo="categoriaSelectionada.nome.toLowerCase()"  :listaTotalAlunos="listaTotalAlunos"  :mediaNotaDaRedeDeEnsino="mediaNotaDaRedeDeEnsino"  :studentsBadGrades="studentsBadGrades" :filtro="filtroSelectionado" :ano="ano" :periodosSelecionados="periodosSelecionados" :escola_id="escolaSelecionada.id" :nomeEscola="escolaSelecionada.nome"/>
      </div>
      <div style="margin-left: 12px;" v-if="categoriaSelectionada.nome.includes('MÉDIA(PRESENÇAS) DOS ALUNOS')">
        <InfoPresencas :tipo="categoriaSelectionada.nome.toLowerCase()"  :mediaPresencaDaRedeDeEnsino="mediaPresencaDaRedeDeEnsino" :studentsBadAttendes="studentsBadAttendes" :filtro="filtroSelectionado" :ano="ano" :escola_id="escolaSelecionada.id" :nomeEscola="escolaSelecionada.nome"/>
      </div>
    </div>
  </div>
</template>

<script>

import InfoTransferidos from '../infoFromCard/infoTransferidos.vue'
import AlunosAtivo from '../infoFromCard/alunosAtivos.vue'
import InfoNotas from '../infoFromCard/infoNotas.vue'
import InfoPresencas from '../infoFromCard/infoPresencas.vue'
import MediaRedeDeEnsino from '../helpers/mediaRedeDeEnsino.vue'

import categorias from '../categorias.json';
import { Escola } from "@/class/escolas";

export default {
  data() {
    return { 
      categoriaSelectionada:  '',
      listaCategoria:  categorias,
      listaTipos:  [],
      listaDeEscolas: [],
      filtroSelectionado: '',
      escolaSelecionada: '',
      exibirComponent: false, 
    }
  },
  name: 'EstatisticaCompleta',
  props: {
    cardId: {
      type: Number,
      required: true
    },
    ano: {
      type: Number,
      required: true
    },
    valueCard : {
      type: Number,
      required: true
    },
    studentsBadGrades : {
      type: Object,
      required: true
    },
    studentsBadAttendes : {
      type: Object,
      required: true
    },
    mediaPresencaDaRedeDeEnsino : {
      type: Number,
      required: true
    },
    mediaNotaDaRedeDeEnsino : {
      type: Number,
      required: true
    },
    periodosSelecionados: {
      type: Array,
      required: true
    },
    listaTotalAlunos: {
      type: Number,
      required: true
    }
   
  },
  components: {
    InfoTransferidos, AlunosAtivo, InfoNotas, InfoPresencas, MediaRedeDeEnsino
  },
  methods: {
    async buscarRelatorio() {
      this.exibirComponent =  false;
      if (this.filtroSelectionado.nome.includes('POR ESCOLA') || this.filtroSelectionado.nome.includes('POR SEGMENTO')  || this.filtroSelectionado.nome.includes('ABAIXO DO ESPERADO') || this.escolaSelecionada != '') {
        this.exibirComponent =  true;
      }
    },
    async buscarEscolas() {
      Escola.obtemTodos()
          .then((resp) => {
            this.listaDeEscolas = resp.data;
          })
          .catch((err) => {
            const errorMessage = err.response.data.erro;
            this.$vaToast.init({
              message: errorMessage,
              iconClass: "fa-star-o",
              position: "top-right",
              color: "danger",
              duration: 2500,
              fullWidth: false,
            });
          })
          .finally(() => {
            
          });
    }
  },
  async beforeMount() {
    await this.buscarEscolas();
    if (this.cardId == 2) {
      this.categoriaSelectionada = this.listaCategoria.find(x => x.nome == "ALUNOS ATIVOS EM TURMA");
      this.listaTipos = this.categoriaSelectionada.opcoes;
    }
    else if (this.cardId == 3) {
      this.categoriaSelectionada = this.listaCategoria.find(x => x.nome == "TRANSFERIDOS EXTERNAMENTE");
      this.listaTipos = this.categoriaSelectionada.opcoes;
    }
    else if (this.cardId == 4) {
      this.categoriaSelectionada = this.listaCategoria.find(x => x.nome == "TRANSFERIDOS INTERNAMENTE");
      this.listaTipos = this.categoriaSelectionada.opcoes;
    }
    else if (this.cardId == 5) {
      this.categoriaSelectionada = this.listaCategoria.find(x => x.nome == "EVADIDOS");
      this.listaTipos = this.categoriaSelectionada.opcoes;
    }
    else if (this.cardId == 6) {
      this.categoriaSelectionada = this.listaCategoria.find(x => x.nome == "MÉDIA (NOTA) DOS ALUNOS");
      this.listaTipos = this.categoriaSelectionada.opcoes;
      this.filtroSelectionado = { "nome": "MÉDIA FINAL ABAIXO DO ESPERADO" };
      this.exibirComponent =  true;
    }
    else if (this.cardId == 7) {
      this.categoriaSelectionada = this.listaCategoria.find(x => x.nome == "MÉDIA(PRESENÇAS) DOS ALUNOS");
      this.listaTipos = this.categoriaSelectionada.opcoes;
      this.filtroSelectionado = { "nome": "MÉDIA FINAL ABAIXO DO ESPERADO" };
      this.exibirComponent =  true;
    }   
  },
}
</script>

<style scoped>

</style>
