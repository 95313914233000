import axios from 'axios'

export class Estatisticas {
  static async obtemTodos(categoria, tipo, ano, filtro, escola_id, periodos) {
    if (ano == null) {
      ano = sessionStorage.getItem("anoSelecionado");
    }
    return axios.get(`/estatisticas?ano=${ano}&categoriaRelatorio=${categoria}&tipoRelatorio=${tipo}&filtro=${filtro}&escola_id=${escola_id}&periodos=${periodos}`);
  }
}
