<template>
  <div class="media-info-card">
    <i :class="['media-info-icon', iconClass]"></i>
    <div class="media-info-content">
      <span class="media-info-label">{{ label }}:</span>
      <span class="media-info-value" v-tooltip.top="tooltipText">
        {{ value }}{{ unit }}
      </span>
    </div>
    <div class="info-nota" v-if="notasNegativas != ''">
      <div class="scores">
        <div>
          <span class="score positive">+50</span>
          <br>
          <span class="score">{{totalDeAlunos - notasNegativas}}</span>
        </div>
        <div>
          <span class="score negative">-50</span>
          <br>
          <span class="score">{{notasNegativas}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MediaInfoCard',
  props: {
    label: {
      type: String,
      required: true
    },
    value: {
      type: [Number, String],
      required: true
    },
    unit: {
      type: String,
      default: '%'
    },
    iconClass: {
      type: String,
      default: 'pi pi-chart-bar'
    },
    tooltipText: {
      type: String,
      required: true
    },
    notasNegativas: {
      type: Number,
      default: 0
    },
    totalDeAlunos: {
      type: Number,
      default: 0
    }
  }
}
</script>

<style scoped>


body {
font-family: Arial, sans-serif;
display: flex;
justify-content: center;
align-items: center;
height: 100vh;
margin: 0;
}

.info-nota {
  padding: 20px;
  text-align: center;
}

h2 {
margin-top: 0;
}
.icon {
  font-size: 25px;
  margin-bottom: 10px;
}
.scores {
  display: flex;
  justify-content: center;
  gap: 20px;
}
.score {
  font-size: 24px;
  font-weight: bold;
}
.positive {
  color: green;
}
.negative {
  color: red;
}


.media-info-card {
  display: inline-flex;
  align-items: center;
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 12px 16px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  margin-bottom: 16px;
}

.media-info-icon {
  margin-top: 6px;
  font-size: 20px;
  color: #3b82f6;
  margin-right: 12px;
}

.media-info-content {
  display: flex;
  align-items: baseline;
}

.media-info-label {
  font-size: 14px;
  color: #333;
  margin-right: 6px;
}

.media-info-value {
  font-size: 18px;
  font-weight: bold;
  color: #3b82f6;
}
</style>