<template>
  <div class="info-ativos">
    <h1 class="page-title">{{filtro.nome}}</h1>

    <SummaryCard title="Total de Alunos" :value="total" />

    
    <div class="chart-container">
      <div ref="chartRef" class="bar"></div>
    </div>

    <div class="button-container" v-if="total > 0">
      <button @click="printChart" class="print-button">Imprimir Gráfico</button>
    </div>
    
    <div class="table-container">
      <pm-Button
        @click="printAllStudentsData"
        type="button"
        label="Imprimir Todos os Alunos"
        title="Imprimir dados de todos os alunos"
        class="p-button-sm p-button-info"
      />
      <br><br>
      <pm-DataTable 
        :value="listaAlunos" 
        :dataKey="'id'" 
        :rowHover="true" 
        :filters="filters"
        :globalFilterFields="['alunosNome']" 
        responsiveLayout="stack"
        :paginator="true"
        :rows="10"
        :rowsPerPageOptions="[5, 10, 20, 50]"
        class="p-datatable-sm"
      >
        <template #header>
          <div class="table-header">
            <pm-InputText v-model="filters['global'].value" placeholder="Buscar aluno..." />
          </div>
        </template>
        
        <pm-Column header="#" :sortable="false">
          <template #body="{ index }">
            {{ index + 1 }}
          </template>
        </pm-Column>
        
        <pm-Column field="alunosNome" header="Nome do Aluno" sortable></pm-Column>
        <pm-Column field="segmento" header="Segmento" sortable></pm-Column>
        <pm-Column field="escola" header="Escola" sortable></pm-Column>
        <pm-Column field="turma" header="Turma" sortable></pm-Column>
        <pm-Column v-if="filtro.nome.includes('TRANSPORTE')" field="transporte" header="Transporte Usado" sortable></pm-Column>
        <pm-Column v-if="filtro.nome == 'ALUNOS POR IDADE'" field="idade" header="Idade" sortable></pm-Column>
      </pm-DataTable>
    </div>
  </div>
</template>

<script>
import { Estatisticas } from "@/class/estatisticas.js";
import SummaryCard from '../helpers/totalAlunos.vue'

import * as echarts from 'echarts';

export default {
  name: 'AlunosAtivos',
  props: {
    tipo: String,
    filtro: String,
    ano: Number,
    escola_id: Number,
    nomeEscola: String
  },
  components: {
    SummaryCard
  },
  data() {
    return {
      listaAlunos: [],
      chartData: null,
      chart: null,
      filters: {
        global: { value: null, matchMode: 'contains' }
      },
      total: 0
    }
  },
  watch: {
    escola_id() {
      this.getALL();
    }
  },
  methods: {
    initChart() {
      if (!this.chart) {
        this.chart = echarts.init(this.$refs.chartRef, null, { renderer: 'svg' });
      }
      this.updateChart();
      window.addEventListener('resize', this.resizeChart);
    },
    updateChart() {
      if (!this.chartData) {
        this.chart.clear();
        console.log('chartData is not ready');
        return;
      }
      let option;

      if (this.filtro.nome.includes('IDADE/ANO')) { 
        if (!Array.isArray(this.chartData.labels) || !Array.isArray(this.chartData.datasets)) {
          console.error('Invalid chart data structure for IDADE/ANO');
          return;
        }
        const labels = this.chartData.labels;
        const datasets = this.chartData.datasets;

        if (datasets.length < 3) {
          console.error('Insufficient datasets for IDADE/ANO chart');
          return;
        }
        const idadeAdequada = datasets[0]?.data || [];
        const idadeAtrasada = datasets[1]?.data || [];
        const idadeAdiantada = datasets[2]?.data || [];
          
        option = {
          title: {
            text: 'Distorção Idade/Série'
          },
          tooltip: {
           
          },
          legend: {
            data: ['Idade Adequada', 'Idade Atrasada', 'Idade Adiantada']
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: {
            type: 'value',
          },
          yAxis: {
            type: 'category',
            data: labels
          },
          series: [
            {
              name: 'Idade Adequada',
              type: 'bar',
              stack: 'total',
              label: {
                show: true,
                formatter: function(params) {
                  return params.value !== 0 ? params.value : '';
                }
              },
              emphasis: {
                focus: 'series'
              },
              itemStyle: {
                color: '#3ef196'  
              },
              data: idadeAdequada
            },
            {
              name: 'Idade Atrasada',
              type: 'bar',
              stack: 'total',
              label: {
                show: true,
                formatter: function(params) {
                  return params.value !== 0 ? params.value : '';
                }
              },
              emphasis: {
                focus: 'series'
              },
              itemStyle: {
                color: '#ef5454' 
              },
              data: idadeAtrasada
            },
            {
              name: 'Idade Adiantada',
              type: 'bar',
              stack: 'total',
              label: {
                show: true,
                formatter: function(params) {
                  return params.value !== 0 ? params.value : '';
                }
              },
              emphasis: {
                focus: 'series'
              },
              itemStyle: {
                color: '#6172e9'
              },
              data: idadeAdiantada
            }
          ]
        };
        
        if (this.chart) {
          try {
            this.chart.setOption(option);
          } catch (error) {
            console.error("Error setting chart options:", error);
          }
        } else {
          console.error("Chart object is not initialized");
        }
      }
      else {
        if (!Array.isArray(this.chartData)) {
            console.error('Invalid chart data structure');
            return;
          }

          const names = this.chartData.map(item => item.nome);
          const totals = this.chartData.map(item => item.total);

          if (names.length === 0 || totals.length === 0) {
            console.error('Empty chart data');
            return;
          }

          const maxTotal = Math.max(...totals);
          const maxAxis = Math.ceil(maxTotal / 5) * 5; 

          option = {
            grid: {
              top: '5%',
              left: '3%',
              right: '5%',
              bottom: '10%',
              containLabel: true
            },
            tooltip: {
              
            },
            yAxis: {
              type: 'category',
              data: names,
              axisLabel: {
                interval: 0,
                rotate: 0,
                formatter: (value) => {
                  return value.length > 15 ? value.slice(0, 35) + '...' : value;
                },
                textStyle: {
                  align: 'right',
                  padding: [0, 15, 0, 0]
                }
              }
            },
            xAxis: {
              type: 'value',
              name: 'Total',
              nameLocation: 'middle',
              nameGap: 30,
              max: maxAxis,
              axisTick: {
                alignWithLabel: true
              },
              axisLabel: {
                formatter: (value) => {
                  return Math.floor(value);
                }
              }
            },
            series: [{
              data: totals,
              type: 'bar',
              barWidth: '60%',
              barGap: '30%',
              itemStyle: {
                borderRadius: [0, 5, 5, 0],
                color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                  {offset: 0, color: '#4B79A1'},
                  {offset: 1, color: '#283E51'}
                ])
              },
              label: {
                show: true,
                position: 'inside',
                formatter: '{c}',
                color: '#fff',
                align: 'center',
                verticalAlign: 'middle'
              }
            }],
          };
          this.chart.setOption(option);
      }
       
      if (this.chart && option) {
        try {
          this.chart.setOption(option);
          this.chart.resize();
        } catch (error) {
          console.error("Error setting chart options:", error);
        }
      } else {
        console.error("Chart object is not initialized or option is not defined");
      }
    },
    resizeChart() {
      this.chart && this.chart.resize();
    },
    async getALL() {
      try {
        const estatisticas = await Estatisticas.obtemTodos("alunosAtivosEmturma", this.tipo, this.ano, this.filtro.nome, this.escola_id);
        this.chartData = estatisticas.data.lista_grafico;
        this.listaAlunos = estatisticas.data.lista_alunos;
        this.total = this.listaAlunos.length;

        if (this.total === 0) {
          this.chartData = [];
          this.listaAlunos = [];
          if (this.chart) {
            this.chart.clear();
          }
          window.alert("Nenhum dado encontrado!");
        } else {
          this.$nextTick(() => {
            this.initChart();
          });
        }
      } catch (error) {
        console.error("Erro ao obter dados:", error);
        this.chartData = []; 
        this.listaAlunos = [];
        if (this.chart) {
          this.chart.clear();
        }
      }
    },

    printAllStudentsData() {
      let printContent = `
        <html>
          <head>
            <style>
              body {
                font-family: Arial, sans-serif;
                margin: 20px;
              }
              h2 {
                text-align: center;
              }
              table {
                width: 100%;
                border-collapse: collapse;
                margin-top: 20px;
              }
              table, th, td {
                border: 1px solid #dddddd;
              }
              th, td {
                padding: 8px;
                text-align: left;
              }
              th {
                background-color: #f2f2f2;
              }
              tr:nth-child(even) {
                background-color: #f9f9f9;
              }
            </style>
          </head>
          <body>
            <h2>DADOS DE ALUNOS ATIVOS EM TURMAS</h2>
            <table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Nome</th>
                  <th>Escola</th>
                  <th>Segmento</th>
                  <th>Turma</th>
                </tr>
              </thead>
              <tbody>
        `;

        this.listaAlunos.forEach((student, index) => {
            printContent += `
              <tr>
                <td>${index + 1}</td>
                <td>${student.alunosNome}</td>
                <td>${student.escola}</td>
                <td>${student.segmento}</td>
                <td>${student.turma}</td>
              </tr>
            `;
        });

        printContent += `
              </tbody>
            </table>
          </body>
        </html>
        `;

        const printWindow = window.open('', '_blank');
        printWindow.document.write(printContent);
        printWindow.document.close();
        printWindow.print();

    },

    printChart() {
      const printWindow = window.open('', '', 'width=800,height=600');
      printWindow.document.write(
        '<html><head><title>' + (this.nomeEscola ? this.nomeEscola.toLocaleUpperCase() + ' - ' : '') + this.filtro.nome + ' - ' + this.ano + '</title></head><body>');
      printWindow.document.write('<div>' + this.$refs.chartRef.innerHTML + '</div>');
      printWindow.document.write('</body></html>');
      printWindow.document.close();
      printWindow.print();
    }
  },
  mounted() {
    this.getALL();
  },
  beforeUnmount() {
    if (this.chart) {
      this.chart.dispose();
      this.chart = null;
    }
    window.removeEventListener('resize', this.resizeChart);
  }
}
</script>

<style scoped>

.info-ativos {
  padding: 20px;
}

.page-title {
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
}

.chart-container {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  margin-bottom: 20px;
  height: 80vh;
  min-height: 600px;
  width: 100%;
}

.bar {
  width: 100%;
  height: 100%;
}

.button-container {
  text-align: right;
  margin-bottom: 20px;
}

.print-button {
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.print-button:hover {
  background-color: #45a049;
}

.table-container {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  padding: 20px;
}

.table-header {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

:deep(.p-datatable-sm) {
  font-size: 0.875rem;
}

:deep(.p-datatable-sm .p-datatable-thead > tr > th) {
  padding: 0.5rem 0.5rem;
}

:deep(.p-datatable-sm .p-datatable-tbody > tr > td) {
  padding: 0.5rem 0.5rem;
}
</style>
