<template>
  <div class="flex">
    <div class="flex-1 mr-4">
      <div class="chart-container" :style="{ height: height }">
        <div ref="chartRef" class="graph-bar-stack"></div>
        <button @click="printChart" class="print-button">Imprimir</button>
      </div>
    </div>
    <div class="student-info">
      <h4>Total de Alunos</h4>
      <div class="student-list">
        <div class="student-item" v-for="student in totalStudents" :key="student.escola">
          <span class="school-name" v-tooltip="student.nome">
            {{ student.nome.substring(0, 20) }}{{ student.nome.length > 20 ? '...' : '' }}
          </span>
          <span class="student-count">{{ student.total }} alunos</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
import { ref, onMounted, onBeforeUnmount, watch } from 'vue';

export default {
  name: 'SegmentosPorEscolas',
  props: {
    chartData: {
      type: Object,
      required: true
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '400px'
    },
    ano: {
      type: Number,
      required: true
    }
  },
  setup(props) {
    const chartRef = ref(null);
    let chart = null;

    const { xAxisData, series } = props.chartData;
    const totalStudents = xAxisData.map((escola, index) => {
        return {
          nome: escola,
          total: series.reduce((sum, serie) => sum + serie.data[index].value, 0)
        };
    }).reverse();

    const initChart = () => {
      if (!chart) {
        chart = echarts.init(chartRef.value, null, { renderer: 'svg' });
      }
      updateChart();
      window.addEventListener('resize', resizeChart);
    };

    const updateChart = () => {
      const { title, xAxisData, series, legend } = props.chartData;
      const option = {
        grid: {
          top: '20%',
          left: '3%',
          right: '4%',
          bottom: '10%',
          containLabel: true
        },
        title: {
          text: title,
          left: 'center',
          top: '20px'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          data: legend,
          top: '40px'
        },
        yAxis: {
          type: 'category',
          data: xAxisData,
          axisLabel: {
            interval: 0,
            rotate: 0
          }
        },
        xAxis: {
          type: 'value'
        },
        series: series.map(item => ({
          name: item.name,
          type: 'bar',
          stack: 'x',
          emphasis: {
            focus: 'series'
          },
          data: item.data,
          itemStyle: { color: item.color },
          barWidth: 20,
          label: {
            show: true,
            position: 'inside',
            formatter: (params) => {
              return params.data.label?.show ? params.data.label.formatter.replace('{c}', params.value) : '';
            }
          }
        })),
        barGap: '30%',
      };

      const minWidth = Math.max(xAxisData.length * 40, legend.length * 100);
      chartRef.value.style.width = `${minWidth}px`;
      chartRef.value.style.minWidth = '100%';

      chart.setOption(option);
      chart.resize();
    };

    const resizeChart = () => {
      chart && chart.resize();
    };

    const printChart = () => {
      const printWindow = window.open('', '', 'width=800,height=600');
      printWindow.document.write(
        '<html><head><title>Alunos por Segmentos em Cada Escola - ' + props.ano + '</title></head><body>');
      printWindow.document.write('<div>' + chartRef.value.innerHTML + '</div>');
      printWindow.document.write('</body></html>');
      printWindow.document.close();
      printWindow.print();
    };

    onMounted(() => {
      initChart();
    });

    watch(() => props.chartData, () => {
      updateChart();
    }, { deep: true });

    onBeforeUnmount(() => {
      if (chart) {
        chart.dispose();
        chart = null;
      }
      window.removeEventListener('resize', resizeChart);
    });

    return { chartRef, printChart, totalStudents };
  }
}
</script>


<style scoped>
.flex {
  display: flex;
  justify-content: space-between;
}

.flex-1 {
  flex: 6 1 0% !important;
}

.mr-4 {
  margin-right: 1rem;
}

.student-info {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.student-list {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 16px;
}

.student-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  background-color: #f5f5f5;
  border-radius: 4px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.school-name {
  font-weight: bold;
}

.student-count {
  color: #666;
}

.chart-container {
  overflow-x: auto;
  background-color: #fff;
  border-radius: 8px;
}

.graph-bar-stack {
  height: 100%;
}

.print-button {
  display: block;
  margin: 20px auto 0;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  color: white;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-bottom: 8px;
}

.print-button:hover {
  background-color: #0056b3;
}
</style>