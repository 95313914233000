<template>
  <div class="summary-container">
    <h2 class="summary-title">
      {{ title }}: <span class="summary-value">{{ value }}</span>
    </h2>
  </div>
</template>

<script>
export default {
  name: 'SummaryCard',
  props: {
    title: {
      type: String,
      required: true
    },
    value: {
      type: [Number, String],
      required: true
    }
  }
}
</script>

<style scoped>
.summary-container {
  background-color: #f0f0f0;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 20px;
  text-align: center;
}

.summary-title {
  font-size: 18px;
  margin: 0;
}

.summary-value {
  font-weight: bold;
  color: #4CAF50;
}
</style>