<template>
  <div v-if=!cardIsSelected>
    <section  v-if="!isLoading"> 
      <div class="select-container">
        <div class="select-wrapper" >
          <label for="class-select">Alterar ano:</label>
          <select id="class-select" v-model="ano" >
            <option value="" disabled selected>Escolha o ano</option>
            <option v-for="info in anos" :value="info.ano">
              {{ info.ano }}
            </option>
          </select>
        </div>
        <div class="select-wrapper" style='margin-top: 2px;'>
          <label for="media">Relação de períodos para média dos alunos</label>
          <div class="select-button-container">
            <pm-MultiSelect
              v-model="periodosSelecionados"
              :options="periodosALL"
              :optionLabel="'nome'"
              :optionValue="'id'"
              placeholder="Selecione um ou mais"
              class="multi-select"
              style="background-color: #efefef;"
            />
            <pm-Button
              type="button"
              @click="buscarInfoCard()"
              label="Buscar Dados"
              title="Buscar Dados"
              class="p-button-sm p-button-success btn-color"
            />
          </div>
        </div>
      </div>
      <div>
        <CardList :ano="ano" :cardList="cardList"  @item-selected="handleItemSelected"/>
      </div>
      <div v-if="chartData['series'].length" style="padding-left: 16px;">
        <SegmentosPorEscolas v-if="chartData" :chartData="chartData" :ano="ano" width="100%" height="820px" />
        <p v-else>Loading chart data...</p>
      </div>
    </section>
    <div v-else class="col-md-12" style="text-align: center;padding-top: 255px;">
      <pm-ProgressSpinner />
      <p>Aguarde...</p>
    </div>
  </div>
  <div v-else>
    <div  class="header-container">
      <h4>Informações Sobre Alunos - {{ ano }}</h4>
      <pm-Button 
        type="button"
        @click="cardIsSelected = false"
        icon="pi pi-angle-right"
        label="VOLTAR"
        title="VOLTAR"
        class="p-button-sm p-button-danger btn-color"
      />
    </div>
    <EstatisticaCompleta :ano="ano" :periodosSelecionados="periodosSelecionados" :cardId="cardId"  :listaTotalAlunos="listaTotalAlunos" :studentsBadGrades="studentsBadGrades" :studentsBadAttendes="studentsBadAttendes" :mediaPresencaDaRedeDeEnsino="mediaPresencaDaRedeDeEnsino"  :mediaNotaDaRedeDeEnsino="mediaNotaDaRedeDeEnsino" :valueCard="value"/>
  </div>

</template>

<script>
import { defineComponent } from 'vue'
import CardList from "@/components/estatisticas/infoOfCard/cardList.vue";
import SegmentosPorEscolas from "@/components/estatisticas/infoOfSegments/segmentosPorEscola.vue";
import EstatisticaCompleta from "@/components/estatisticas/infoFromCard/estatisticaCompleta.vue";

import { Estatisticas } from "@/class/estatisticas.js";

export default defineComponent({
  components: { CardList, SegmentosPorEscolas, EstatisticaCompleta },
  data() {
    return {
      periodosALL: [
        { id: 1, nome: "1º Período" },
        { id: 2, nome: "2º Período" },
        { id: 3, nome: "3º Período" },
        { id: 4, nome: "4º Período" },
      ],
      periodosSelecionados: [1, 2, 3, 4],
      chartData: null,
      cardList: null,
      studentsBadGrades: [],
      studentsBadAttendes: [],
      listaTotalAlunos: 0,
      mediaPresencaDaRedeDeEnsino: 0.0,
      mediaNotaDaRedeDeEnsino: 0.0,
      isLoading: true,
      error: null,
      ano: 0,
      cardIsSelected: false,
      cardId: 0,
      value: 0,
      anos: sessionStorage.getItem("listaAnos") ? JSON.parse(sessionStorage.getItem("listaAnos")) : [],
    }
  },
  methods: {
    async buscarInfoCard() {
      if (this.periodosSelecionados.length == 0) {
        window.alert("Selecione pelo menos um período!");
        return;
      }
      this.isLoading = true;
      await this.loadCardList();
      this.isLoading = false;
    },
    handleItemSelected(cardId, value) {
      this.cardIsSelected = true;
      this.cardId = cardId;
      this.value = value;
    },
    async handleClick() {
      this.isLoading = true;
      await this.loadCardList();
      await this.loadBarYCategoryStack();
      this.isLoading = false;
    },
    async loadCardList() {
      let estatisticas = await Estatisticas.obtemTodos("infoCards", "", this.ano, null, null, String(this.periodosSelecionados));
      this.studentsBadGrades =  estatisticas.data.notasAlunos.lista_alunos;
      this.studentsBadAttendes = estatisticas.data.presencasAlunos.lista_alunos;
      this.listaTotalAlunos = estatisticas.data.notasAlunos.total_alunos;
      this.mediaPresencaDaRedeDeEnsino = estatisticas.data.presencasAlunos.media_presenca_rede_de_ensino;
      this.mediaNotaDaRedeDeEnsino = estatisticas.data.notasAlunos.media_nota_rede_de_ensino;
      this.cardList = estatisticas.data;
    },
    async loadBarYCategoryStack() {
      // let estatisticas = await Estatisticas.obtemTodos("QuantidadeDeAlunos", "QARES", this.ano);
      let estatisticas = await Estatisticas.obtemTodos("quantidadeDeAlunosPorSegmento", "", this.ano);
      if (estatisticas && estatisticas.data && estatisticas.data) {
        this.chartData = this.transformData(estatisticas.data);
      } else {
        throw new Error('Invalid data structure received');
      }
    },
    transformData(data) {
      // Get all unique segments
      const allSegments = [...new Set(data.flatMap(school =>
        school.segmento_Lista.map(seg => seg.nome)
      ))];

      // Prepare the data for the chart
      const chartData = {
        title: 'Quantidade de Alunos Ativos por Escola e Segmento',
        xAxisData: data.map(school => school.escola_nome),
        legend: allSegments,
        series: allSegments.map(segmentName => ({
          name: segmentName,
          type: 'bar',
          stack: 'x',
          emphasis: {
            focus: 'series'
          },
          data: data.map(school => {
            const segment = school.segmento_Lista.find(seg => seg.nome === segmentName);
            const value = segment ? segment.quantidade : 0;
            return {
              value: value,
              label: {
                show: value > 0,
                position: 'inside',
                formatter: `{c}`
              }
            };
          }),
          barWidth: 20
        }))
      };
      return chartData;
    },
  },
  async beforeMount() {
    this.isLoading = true;
    this.ano = parseInt(sessionStorage.getItem("anoSelecionado"));
    await this.loadCardList();
    await this.loadBarYCategoryStack();
    this.isLoading = false;

  }
})
</script>

<style scoped>

.select-button-container {
  display: flex;
  align-items: center;
  gap: 10px; 
}

.multi-select {
  flex-grow: 1;
}

.btn-color {
  white-space: nowrap; 
}


.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-container h4 {
  margin: 0;
}
.select-container {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  margin-bottom: 20px;
  padding-left: 17px; 
}

.select-wrapper {
  width: auto;
  min-width: 200px;
  max-width: 800px;
}

.select-wrapper label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 14px;
}

.select-wrapper select {
  width: 100%;
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

@media (max-width: 600px) {
  .select-container {
    flex-direction: column;
  }

  .select-wrapper {
    width: 100%;
    max-width: none;
  }
}  
</style>