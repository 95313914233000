<template>
  <div class="info-transferidos">
    <MediaRedeDeEnsino
      label="Média Gerais"
      :value="mediaNotaDaRedeDeEnsino"
      :totalDeAlunos="listaTotalAlunos"
      :notasNegativas="studentsBadGrades.length"
      unit=""
      tooltip-text="Média de notas de todos os alunos da rede de ensino"
    />
    <div v-if="showChart || (escola_id != '' && escola_id != undefined)">
      <h1 class="page-title">ALUNOS - {{ filtro.nome }}</h1>
      
      <div class="chart-container">
        <div ref="chartRef" class="bar"></div>
      </div>

      <div class="button-container">
        <button @click="printChart" class="print-button" v-if="!isMobile" aria-label="Imprimir Gráfico">
          Imprimir Gráfico  
        </button>
      </div>
    </div>
    <div v-else class="table-container">
      <pm-Button
        @click="printAllStudentsData"
        type="button"
        label="Imprimir Todos os Alunos"
        title="Imprimir dados de todos os alunos"
        class="p-button-sm p-button-info"
      />
      <br><br>
      <div>
    
        
      </div>
      <pm-DataTable
        :value="studentsBadGrades"
        :dataKey="'id'"
        :rowHover="true"
        :filters="filters"
        :globalFilterFields="['nome_completo', 'escola_nome', 'segmento_nome']"
        responsiveLayout="stack"
        :paginator="true"
        :rows="25"
        :rowsPerPageOptions="[25, 50, 75, 100]"
        class="p-datatable-sm"
      >
        <template #header>
          <div class="table-header">
            <pm-InputText v-model="filters['global'].value" placeholder="Buscar aluno..." aria-label="Buscar aluno" />
            &ensp;
            <pm-Dropdown v-model="filters['escola_nome'].value" :options="schoolOptions" optionLabel="label" optionValue="value" placeholder="Filtrar por Escola" class="p-column-filter" aria-label="Filtrar por Escola" />
            &ensp;
            <pm-Dropdown v-model="filters['segmento_nome'].value" :options="segmentOptions" optionLabel="label" optionValue="value" placeholder="Filtrar por Segmento" class="p-column-filter" aria-label="Filtrar por Segmento" />
          </div>
        </template>
        
        <pm-Column header="#" :sortable="false">
          <template #body="{ index }">
            {{ index + 1 }}
          </template>
        </pm-Column>
        
        <pm-Column field="nome_completo" header="Nome do Aluno" sortable></pm-Column>
        <pm-Column field="escola_nome" header="Escola" sortable></pm-Column>
        <pm-Column field="segmento_nome" header="Segmento" sortable></pm-Column>
        <pm-Column field="nome_turma" header="Turma" sortable></pm-Column>
        <pm-Column header="Disciplinas" :sortable="false">
          <template #body="slotProps">
            <pm-Button
              @click="openDisciplinasModal(slotProps.data)"
              type="button"
              label="Ver Disciplinas"
              title="Ver Disciplinas com nota abaixo da média"
              class="p-button-sm p-button-warning btn-color"
            />
          </template>
        </pm-Column>
      </pm-DataTable>
    </div>

    <pm-Dialog v-model:visible="isDisciplinasModalVisible" header="Disciplinas" :modal="true">
      <div v-if="selectedStudent" class="disciplinas-dialog">
        <h3 class="student-name">{{ selectedStudent.nome_completo }}</h3>
        <ul class="disciplinas-list">
          <li v-for="disciplina in selectedStudent.disciplinas" :key="disciplina.nome_disciplina" class="disciplina-item">
            <span class="disciplina-nome">{{ disciplina.nome_disciplina }}</span>
            <span class="media-final">Média Final: {{ disciplina.media_final }}</span>
          </li>
        </ul>
      </div>
    </pm-Dialog>
  </div>
</template>

<script>
import { ref, computed, onMounted, watch, onBeforeUnmount } from 'vue';
import { FilterMatchMode } from 'primevue/api';
import { Estatisticas } from "@/class/estatisticas.js";
import SummaryCard from '../helpers/totalAlunos.vue'
import MediaRedeDeEnsino from '../helpers/mediaRedeDeEnsino.vue';
import * as echarts from 'echarts';

export default {
  name: 'AlunosAtivos',
  components: { SummaryCard, MediaRedeDeEnsino },
  props: {
    tipo: String,
    filtro: Object,
    ano: Number,
    escola_id: Number,
    nomeEscola: String,
    studentsBadGrades: Array,
    mediaNotaDaRedeDeEnsino: {
      type: Number,
      default: 0.0
    },
    periodosSelecionados: Array,
    listaTotalAlunos: Number
  },
  setup(props) {
    const chartRef = ref(null);
    const chart = ref(null);
    const chartData = ref(null);
    const isDisciplinasModalVisible = ref(false);
    const selectedStudent = ref(null);
    const showChart = ref(false);
    const isMobile = ref(window.innerWidth < 768);
    const notaExibirPor = ref(null);

    const filters = ref({
      global: { value: null, matchMode: 'contains' },
      escola_nome: { value: null, matchMode: FilterMatchMode.EQUALS },
      segmento_nome: { value: null, matchMode: FilterMatchMode.EQUALS }
    });

    const schoolOptions = computed(() => 
      [...new Set(props.studentsBadGrades.map(student => student.escola_nome))]
        .map(school => ({ label: school, value: school }))
    );

    const segmentOptions = computed(() => 
      [...new Set(props.studentsBadGrades.map(student => student.segmento_nome))]
        .map(segment => ({ label: segment, value: segment }))
    );

    const initChart = () => {
      if (!chart.value) {
        chart.value = echarts.init(chartRef.value, null, { renderer: 'svg' });
      }
      updateChart();
      window.addEventListener('resize', resizeChart);
    };

    const createChartOption = () => {
      if (!chartData.value || !Array.isArray(chartData.value) || chartData.value.length === 0) {
        console.error('Invalid or empty chart data');
        return null;
      }

      const names = chartData.value.map(item => item.nome);
      const totals = chartData.value.map(item => item.media);
      const maxTotal = Math.max(...totals);
      const maxAxis = Math.ceil(maxTotal / 5) * 5;

      return {
        grid: {
          top: '5%',
          left: '3%',
          right: '5%',
          bottom: '10%',
          containLabel: true
        },
        tooltip: {},
        yAxis: {
          type: 'category',
          data: names,
          axisLabel: {
            interval: 0,
            rotate: 0,
            formatter: (value) => value.length > 15 ? value.slice(0, 35) + '...' : value,
            textStyle: { align: 'right', padding: [0, 15, 0, 0] }
          }
        },
        xAxis: {
          type: 'value',
          name: 'Média',
          nameLocation: 'middle',
          nameGap: 30,
          max: maxAxis,
          axisTick: { alignWithLabel: true },
          axisLabel: { formatter: (value) => Math.floor(value) }
        },
        series: [{
          data: totals,
          type: 'bar',
          barWidth: '60%',
          barGap: '30%',
          itemStyle: {
            borderRadius: [0, 5, 5, 0],
            color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
              {offset: 0, color: '#4B79A1'},
              {offset: 1, color: '#283E51'}
            ])
          },
          label: {
            show: true,
            position: 'inside',
            formatter: '{c}',
            color: '#fff',
            align: 'center',
            verticalAlign: 'middle'
          }
        }]
      };
    };

    const updateChart = () => {
      if (!chart.value || !chartData.value || chartData.value.length === 0) {
          chart.value && chart.value.clear();
          return;
      }
      const option = createChartOption();
      if (chart.value && option) {
        try {
          chart.value.setOption(option);
        //  chart.value.resize();
        } catch (error) {
          console.error("Error setting chart options:", error);
        }
      }
    };

    const resizeChart = () => {
      chart.value && chart.value.resize();
      isMobile.value = window.innerWidth < 768;
    };

    const getALL = async () => {
      try {
        const response = await Estatisticas.obtemTodos("mediaNotas", props.tipo, props.ano, props.filtro.nome, props.escola_id, props.periodosSelecionados);
        chartData.value = response.data.lista_grafico;
        if (chartData.value.length === 0) {
          chartData.value = [];
          updateChart();
          window.alert("Nenhum dado encontrado. Por favor, verifique se há alunos registrados nos níveis Fundamental 1, Fundamental 2 ou EJA na escola selecionada.");

        }
        initChart();
      } catch (error) {
        console.error("Erro ao obter dados:", error);
        chartData.value = [];
        chart.value && chart.value.clear();
      }
    };

    if (props.escola_id != '' && props.escola_id != undefined) {
      getALL();
      showChart.value = true;
    }

    const openDisciplinasModal = (student) => {
      selectedStudent.value = student;
      isDisciplinasModalVisible.value = true;
    };
    const printChart = () => {
      const printWindow = window.open('', '', 'width=800,height=600');
      printWindow.document.write(`
        <html>
          <head><title>${props.nomeEscola ? props.nomeEscola.toLocaleUpperCase() + ' - ' : ''} ${props.filtro.nome} - ${props.ano}</title></head>
          <body>
            <div>${chartRef.value.innerHTML}</div>
          </body>
        </html>
      `);
      printWindow.document.close();
      printWindow.print();
    };

    const printAllStudentsData = () => {
      if (filters.value['segmento_nome'].value == null) {
        window.alert("Para imprimir os dados dos alunos, é necessário selecionar um segmento na lista de opções da tabela. Tente novamente!");
      }
      let disciplinas = [];
      let listaAlunosParaImprimir = props.studentsBadGrades.filter(student => student['segmento_nome'] == filters.value['segmento_nome'].value);

      for (let i = 0; i < listaAlunosParaImprimir[0]['todas_disciplinas'].length; i++) {
        disciplinas.push(listaAlunosParaImprimir[0]['todas_disciplinas'][i].nome_disciplina);
      }

      let printContent = `
        <html>
          <head>
            <style>
              body {
                font-family: Arial, sans-serif;
                margin: 20px;
              }
              h2 {
                text-align: center;
              }
              table {
                width: 100%;
                border-collapse: collapse;
                margin-top: 20px;
              }
              table, th, td {
                border: 1px solid #dddddd;
              }
              th, td {
                padding: 8px;
                text-align: center;
              }
              th {
                background-color: #f2f2f2;
              }
              tr:nth-child(even) {
                background-color: #f9f9f9;
              }
              .media-final {
                font-weight: bold;
                background-color: #e6e6e6;
              }
              .vertical-text {
                writing-mode: vertical-rl;
                text-orientation: mixed;
                transform: rotate(180deg);
                white-space: nowrap;
                padding: 10px 0;
              }
            </style>
          </head>
          <body>
            <h2>DADOS DE ALUNOS - ${filters.value['segmento_nome'].value.toUpperCase() }</h2>
            <table>
              <thead>
                <tr>
                  <th rowspan="2">#</th>
                  <th rowspan="2">Nome</th>
                  <th rowspan="2">Escola</th>
                  <th rowspan="2">Segmento</th>
                  <th rowspan="2">Turma</th>
                  <th colspan="${disciplinas.length}" class="media-final">MÉDIA FINAL</th>
                </tr>
                <tr>
                  ${disciplinas.map(d => `<th class="vertical-text">${d}</th>`).join('')}
                </tr>
              </thead>
              <tbody>
      `;

      listaAlunosParaImprimir.forEach((student, index) => {
        printContent += `
          <tr>
            <td>${index + 1}</td>
            <td>${student.nome_completo}</td>
            <td>${student.escola_nome}</td>
            <td>${student.segmento_nome}</td>
            <td>${student.nome_turma}</td>
            ${student.todas_disciplinas.map(d => {
              const mediaFinal = parseFloat(d.media_final);

              if (!isNaN(mediaFinal)) {
                const color = mediaFinal >= 50 ? 'blue' : 'red';
                return `<td style="color: ${color}">${mediaFinal}</td>`;
              } else {
                return '<td></td>';
              }
            }).join('')}
          </tr>

        `;
        printContent += `</tr>`;
      });

      printContent += `
              </tbody>
            </table>
          </body>
        </html>
      `;

      const printWindow = window.open('', '_blank');
      printWindow.document.write(printContent);
      printWindow.document.close();
      printWindow.print();
    };


    watch(() => props.escola_id, () => {
      if (props.filtro.nome === 'MÉDIA POR TURMA') {
        getALL();
        showChart.value = true;
      }
    });

    watch(() => props.filtro, () => {
      showChart.value = !['MÉDIA FINAL ABAIXO DO ESPERADO', 'MÉDIA POR TURMA'].includes(props.filtro.nome);
      if (showChart.value) getALL();
    });

    onMounted(() => {
      window.addEventListener('resize', resizeChart);
    });

    onBeforeUnmount(() => {
      window.removeEventListener('resize', resizeChart);
    });

    return {
      chartRef,
      filters,
      isDisciplinasModalVisible,
      selectedStudent,
      showChart,
      isMobile,
      schoolOptions,
      segmentOptions,
      openDisciplinasModal,
      printChart,
      printAllStudentsData,
    };
  }
};
</script>

<style scoped>
.disciplina-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}

.disciplina-nome {
  font-weight: bold;
}

.media-final {
  margin-left: 16px;
}

.disciplinas-dialog {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
}

.student-name {
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 15px;
  color: #333;
}

.disciplinas-list {
  list-style-type: none;
  padding: 0;
}

.disciplina-item {
  padding: 10px;
  border-bottom: 1px solid #ddd;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.disciplina-item:last-child {
  border-bottom: none;
}

.media-final {
  font-size: 1em;
  color: #666;
}

@media (max-width: 600px) {
  .disciplinas-dialog {
    padding: 10px;
  }

  .student-name {
    font-size: 1.2em;
  }

  .disciplina-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .media-final {
    margin-top: 5px;
  }
}




























.summary-container {
  background-color: #f0f0f0;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 20px;
  text-align: center;
}

.summary-title {
  font-size: 18px;
  margin: 0;
}

.summary-value {
  font-weight: bold;
  color: #4CAF50;
}

.info-ativos {
  padding: 20px;
}

.page-title {
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
}

.chart-container {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  margin-bottom: 20px;
  height: 80vh;
  min-height: 600px;
  width: 100%;
}

.bar {
  width: 100%;
  height: 100%;
}

.button-container {
  text-align: right;
  margin-bottom: 20px;
}

.print-button {
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.print-button:hover {
  background-color: #45a049;
}

.table-container {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  padding: 20px;
}

.table-header {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

:deep(.p-datatable-sm) {
  font-size: 0.875rem;
}

:deep(.p-datatable-sm .p-datatable-thead > tr > th) {
  padding: 0.5rem 0.5rem;
}

:deep(.p-datatable-sm .p-datatable-tbody > tr > td) {
  padding: 0.5rem 0.5rem;
}
</style>
