<template>
  <div class="info-transferidos">
    <h1 class="page-title">Alunos Ativos</h1>

    <SummaryCard title="Total de Alunos" :value="total" />

    
    <div class="chart-container">
      <div ref="chartRef" class="bar"></div>
    </div>

    <div class="button-container">
      <button v-if="total > 0" @click="printChart" class="print-button">Imprimir Gráfico</button>
    </div>
    
    <div class="table-container">
      <pm-Button
        @click="printAllStudentsData"
        type="button"
        label="Imprimir Todos os Alunos"
        title="Imprimir dados de todos os alunos"
        class="p-button-sm p-button-info"
      />
      <br><br>
      <pm-DataTable 
        :value="listaAlunos" 
        :dataKey="'id'" 
        :rowHover="true" 
        :filters="filters"
        :globalFilterFields="['alunosNome']" 
        responsiveLayout="stack"
        :paginator="true"
        :rows="25"
        :rowsPerPageOptions="[25, 50, 75, 100]"
        class="p-datatable-sm"
      >
        <template #header>
          <div class="table-header">
            <pm-InputText v-model="filters['global'].value" placeholder="Buscar aluno..." />
          </div>
        </template>
        
        <pm-Column header="#" :sortable="false">
          <template #body="{ index }">
            {{ index + 1 }}
          </template>
        </pm-Column>
        
        <pm-Column field="alunosNome" header="Nome do Aluno" sortable></pm-Column>
        <pm-Column field="segmento" header="Segmento" sortable></pm-Column>
        <pm-Column field="escola" header="Escola" sortable></pm-Column>
        <pm-Column field="turma" header="Turma" sortable></pm-Column>
      </pm-DataTable>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, watch, onBeforeUnmount } from 'vue';
import { Estatisticas } from "@/class/estatisticas.js";
import SummaryCard from '../helpers/totalAlunos.vue'
import * as echarts from 'echarts';

export default {
  name: 'InfoTransferidos',
  props: {
    tipo: String,
    filtro: String,
    ano: Number,
    escola_id: Number,
    nomeEscola: String

  },
  components: {
    SummaryCard
  },
  setup() {
    const chartRef = ref(null);
    let chart = null;
    const chartData = ref([]);
    const filters = ref({
      global: { value: null, matchMode: 'contains' }
    });

    const initChart = () => {
      if (!chart) {
            chart = echarts.init(chartRef.value, null, { renderer: 'svg' });
          }
          updateChart();
          window.addEventListener('resize', resizeChart);
    };
    const updateChart = () => {
        if (!chart || !chartData.value.length) {
          chart.clear(); 
          return;
        }

        const names = chartData.value.map(item => item.nome);
        const totals = chartData.value.map(item => item.total);

        const maxTotal = Math.max(...totals);
        const maxAxis = Math.ceil(maxTotal / 5) * 5; 

        const option = {
          grid: {
            top: '5%',
            left: '3%',  // Aumentado para acomodar rótulos mais longos
            right: '5%',
            bottom: '10%',
            containLabel: true
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          yAxis: {
            type: 'category',
            data: names,
            axisLabel: {
              interval: 0,
              rotate: 0,
              formatter: (value) => {
                return value.length > 15 ? value.slice(0, 35) + '...' : value;
              },
              textStyle: {
                align: 'right',
                padding: [0, 15, 0, 0]  // Aumentado o espaçamento à direita
              }
            }
          },
          xAxis: {
            type: 'value',
            name: 'Total',
            nameLocation: 'middle',
            nameGap: 30,
            max: maxAxis,
            axisTick: {
              alignWithLabel: true
            },
            axisLabel: {
              formatter: (value) => {
                return Math.floor(value);
              }
            }
          },
          series: [{
            data: totals,
            type: 'bar',
            barWidth: '60%',  // Ajustado para porcentagem para melhor responsividade
            barGap: '30%',    // Aumenta o espaço entre as barras
            itemStyle: {
              borderRadius: [0, 5, 5, 0],
              color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                {offset: 0, color: '#4B79A1'},
                {offset: 1, color: '#283E51'}
              ])
            },
            label: {
                show: true,
                position: 'inside',
                formatter: '{c}',
                color: '#fff',
                align: 'center',
                verticalAlign: 'middle'
            }
          }],
        };
        chart.setOption(option);
        chart.resize();
    };
    const resizeChart = () => {
      chart && chart.resize();
    };

    onMounted(() => {
      initChart();
    });

    watch(chartData, () => {
      updateChart();
    }, { deep: true });

    onBeforeUnmount(() => {
      if (chart) {
        chart.dispose();
        chart = null;
      }
      window.removeEventListener('resize', resizeChart);
    });
    return { chartRef, chartData, filters, updateChart };
  },
  data() {
    return {
      listaAlunos: [],
      total: 0
    }
  },
  watch: {
    escola_id() {
      this.getALL();
    }
  },
  methods: {
    printAllStudentsData() {
      let printContent = `
        <html>
          <head>
            <style>
              body {
                font-family: Arial, sans-serif;
                margin: 20px;
              }
              h2 {
                text-align: center;
              }
              table {
                width: 100%;
                border-collapse: collapse;
                margin-top: 20px;
              }
              table, th, td {
                border: 1px solid #dddddd;
              }
              th, td {
                padding: 8px;
                text-align: left;
              }
              th {
                background-color: #f2f2f2;
              }
              tr:nth-child(even) {
                background-color: #f9f9f9;
              }
            </style>
          </head>
          <body>
            <h2>DADOS DE ALUNOS QUE FORAM ${this.filtro.nome.toUpperCase()}</h2>
            <table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Nome</th>
                  <th>Escola</th>
                  <th>Segmento</th>
                  <th>Turma</th>
                </tr>
              </thead>
              <tbody>
        `;

        this.listaAlunos.forEach((student, index) => {
            printContent += `
              <tr>
                <td>${index + 1}</td>
                <td>${student.alunosNome}</td>
                <td>${student.escola}</td>
                <td>${student.segmento}</td>
                <td>${student.turma}</td>
              </tr>
            `;
        });

        printContent += `
              </tbody>
            </table>
          </body>
        </html>
        `;

        const printWindow = window.open('', '_blank');
        printWindow.document.write(printContent);
        printWindow.document.close();
        printWindow.print();

    },
    printChart() {
      const printWindow = window.open('', '', 'width=800,height=600');
      printWindow.document.write(
        '<html><head><title>' + (this.nomeEscola ? this.nomeEscola.toLocaleUpperCase() + ' - ' : '') + this.filtro.nome + ' - ' + this.ano + '</title></head><body>');
      printWindow.document.write('<div>' + this.$refs.chartRef.innerHTML + '</div>');
      printWindow.document.write('</body></html>');
      printWindow.document.close();
      printWindow.print();
    },
    async getALL() {
      try {
        const estatisticas = await Estatisticas.obtemTodos("movimentacaoDoAlunos", this.tipo, this.ano, this.filtro.nome, this.escola_id);
        this.chartData = estatisticas.data.lista_grafico;
        this.listaAlunos = estatisticas.data.lista_alunos;
        this.total = this.listaAlunos.length;
        
        // Limpar dados se o total for zero
        if (this.total === 0) {
          this.chartData = [];
          this.listaAlunos = [];
          this.updateChart();
          window.alert("Nenhum dado encontrado!");
        }
      } catch (error) {
        console.error("Erro ao obter dados:", error);
        // Limpar dados em caso de erro
        this.chartData = [];
        this.listaAlunos = [];
        this.total = 0;
        this.updateChart(); 
      }
    }
  },
  mounted() {
    this.getALL();
  }
}
</script>

<style scoped>
.info-transferidos {
  padding: 20px;
}

.page-title {
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
}

.chart-container {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  margin-bottom: 20px;
  height: 80vh;  /* Ajustado para usar altura relativa */
  min-height: 600px;  /* Altura mínima para telas menores */
  width: 100%;
}
.bar {
  width: 100%;
  height: 100%;
}

.button-container {
  text-align: right;
  margin-bottom: 20px;
}

.print-button {
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.print-button:hover {
  background-color: #45a049;
}


.table-container {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  padding: 20px;
}

.table-header {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

:deep(.p-datatable-sm) {
  font-size: 0.875rem;
}

:deep(.p-datatable-sm .p-datatable-thead > tr > th) {
  padding: 0.5rem 0.5rem;
}

:deep(.p-datatable-sm .p-datatable-tbody > tr > td) {
  padding: 0.5rem 0.5rem;
}
</style>